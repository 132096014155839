html {
	font-size: 16px;
	font-family: "Raleway", sans-serif;
	color: #424242;
}

html,
body {
	width: 100%;
	position: relative;
	margin: 0;
	padding: 0;
	font-family: "Raleway", sans-serif;
}

body {
	min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
	font-family: "Raleway", sans-serif;

	a {
		color: inherit;
	}
}

ul,
p {
	margin: 0;
	padding: 0;
}

.wow {
	visibility: hidden;
}

a {
	color: $primary;
	text-decoration: none;
}

header {
	a:hover {
		text-decoration: none;
	}
}

main {
	overflow: hidden;
	clear: both;
	position: relative;
	padding-bottom: 2.5rem;
	min-height: 80vh;

	ul {
		margin: 0 0 0 2.5rem;
		padding: 0;
	}
}

.jumbotron {
	padding: 30px 0;
	border-radius: 0;
	border: none;
	margin: 0;
	color: $primary;

	h1 {
		color: currentColor;
		text-align: center;
		font-size: 2.5rem;
		margin: 0;
		font-weight: 400;
	}
}

footer {
	background: #303030;
	color: white;
	padding: 6rem 0;

	p {
		margin: 0;
		padding: 0;
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	a:hover {
		text-decoration: underline;
	}
}
.idiomes {
	list-style: none;

	li {
		float: left;
		display: inline;
		margin: 0 3px;
	}

	li a {
		color: #333;
	}

	li a.selected {
		color: #333;
		font-weight: bold;
	}
}

.titolweb {
	position: absolute;
	left: 10px;
	display: flex;
	height: 100%;
	align-content: center;
}

.contingutslide {
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin-top: 6rem;

	.slideimg {
		display: flex;
		justify-content: center;

		img {
			height: 205px;
		}
	}

	.slidetext {
		padding: 1rem 0 4rem 0;

		p {
			color: #fff;
			font-size: 25px;
			font-weight: 700;
		}
	}

	.slidebuto {
		.btn {
			padding: 1.2rem 5.8rem;
			background-color: #2574bc;
			border-radius: 50px;
			border-color: #2574bc;
		}
		a {
			font-size: 18px;
			font-weight: 700;
			color: #fff;
		}
		a:hover {
			opacity: 0.9;
		}
	}
}

#serveis {
	padding: 10rem 0;
	h3 {
		color: #303030;
		font-size: 16px;
		line-height: 28px;
		font-style: normal;
		font-weight: 700;
		letter-spacing: 0px;
		margin-bottom: 0px;
	}
	p {
		color: #828282;
		font-size: 14px;
		line-height: 26px;
		font-style: normal;
		font-weight: 400;
	}
}

#banner {
	.container {
		max-width: 100%;
	}
	img {
		max-width: 300px;
	}

	.lliures .grid-item:last-child {
		margin-bottom: 2.5rem;
	}
	.grid {
		padding-top: 2rem;
	}
}

footer {
	.titlefooter1,
	.titlefooter2 {
		border-bottom: 0.5px solid #ababab;
		h5 {
			color: #ababab;
			font-size: 12px;
			letter-spacing: 0px;
			line-height: 28px;
			font-weight: 700;
			text-transform: uppercase;
			margin-bottom: 18px;
		}
	}

	p {
		font-size: 14px;
		letter-spacing: 0px;
		line-height: 23px;
		font-weight: 400;
	}
	.fdireccio p:last-child,
	.ftel p:last-child,
	.fcorreu p:last-child {
		margin-left: 10px !important;
	}
	a:hover {
		font-weight: 500;
		color: #ababab;
	}

	.titlefooter1 {
		margin-right: 25px;
	}

	.footertextalign2 {
		text-align: end;
	}
}

section.composades {
	padding: 0;
}

#services1 {
	.container {
		max-width: 100%;
		padding: 0;
		margin: 0;
	}
}

#about-us {
	.lliures {
		margin: 8rem 0 3rem 0;
	}
	p {
		color: #303030;
		font-size: 22px;
		line-height: 32px;
		font-style: normal;
		font-weight: 700;
		letter-spacing: 0px;
		text-transform: none;
	}
}

#contact {
	.lliures {
		padding: 7rem 0 3rem 0;
	}
	.contactme {
		margin-left: 4rem;

		h3 {
			font-size: 30px;
			line-height: 1.25em;
			color: #303030;
		}
		p {
			color: #303030;
			font-size: 16px;
			line-height: 28px;
			font-style: normal;
			font-weight: 700;
			letter-spacing: 0px;
			text-transform: none;
		}
		a {
			color: #303030;
			font-size: 16px;
			line-height: 28px;
			font-style: normal;
			font-weight: 700;
			letter-spacing: 0px;
			text-transform: none;
		}
		a:hover {
			color: #828282;
		}
	}
}

.formcontacta {
	background-color: #dddddd;
	padding: 2rem;
	border-radius: 10px;

	.btn {
		background-color: #303030;
		border-color: #303030;
	}
}

.getintouch {
	img {
		transition: transform 0.3s ease;
		transform: scale(0.8);
	}

	img:hover {
		transform: scale(1); /* Cambia el 1.2 por el factor de escala deseado */
	}
}

.linia {
	padding-bottom: 20px;
	border-top: 1px solid #eaeaea;
	margin-top: 20px;
	border-bottom: 0;
}

#serveistatic1,
#serveistatic2 {
	margin: 7rem 0;
	.requadre {
		padding: 0 35px;
		line-height: 36px;
		top: -19px;
		font-size: 12px;
		color: #fff;
		font-weight: 700;
		background-color: #2574bc;
		border-radius: 2px;
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		-o-border-radius: 2px;
		border: 1px solid transparent;
		display: inline-block;
		position: relative;

		span {
			font-size: 12px;
			color: #fff;
			font-weight: 700;
		}
	}
}
#services2 {
	h3 {
		color: #303030;
		font-size: 16px;
		line-height: 28px;
		font-style: normal;
		font-weight: 700;
		letter-spacing: 0px;
		margin-bottom: 0px;
	}
	p {
		color: #828282;
		font-size: 14px;
		line-height: 26px;
		font-style: normal;
		font-weight: 400;
	}
}

#serveistatic2 {
	margin: 7rem 0 3rem 0;
}

#services3 {
	padding-bottom: 2rem;
	li {
		color: #303030;
		font-size: 16px;
		line-height: 28px;
		font-style: normal;
		font-weight: 700;
	}
}

.animated-text {
	font-size: 24px; /* Adjust font size as needed */
	overflow: hidden; /* Hide overflowing text */
	white-space: nowrap; /* Prevent line breaks */
	position: relative; /* Required for absolute positioning */
}

.animated-text::before {
	content: attr(data-text); /* Use data-text attribute for animation */
	position: absolute; /* Position relative to .animated-text */
	top: 0;
	left: 0;
	width: 0; /* Initially start with no width */
	height: 100%; /* Full height */
	background-color: transparent; /* Background color (adjust as needed) */
	color: #fff; /* Text color (adjust as needed) */
	overflow: hidden; /* Hide overflow to prevent content from showing */
	animation: reveal 4s 1s forwards; /* Animation for revealing text */
}

@keyframes reveal {
	0% {
		width: 0; /* Start with no width (invisible) */
	}
	100% {
		width: 100%; /* End with full width (fully visible) */
	}
}

#legal-advice,
#privacy-policy,
#cookies-policy {
	margin-top: 100px;
	p,
	li {
		color: #828282;
		font-size: 14px;
		line-height: 26px;
		font-style: normal;
		font-weight: 400;
	}
	h1 {
		color: #000;
		font-weight: 600;
	}
}

#contact iframe {
	height: 450px;
	width: 600px;
}
