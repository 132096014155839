#menu {
	font-family: "Raleway", sans-serif;
}

@media only screen and (max-width: 992px) {
	#box_menu {
		z-index: 10000;
	}
	#menu {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 100000;
		margin: 0;
		overflow: auto;
		max-height: 100%;
		background: #303030;
		box-shadow: 0 3px 5px rgba(57, 63, 72, 0.2);
	}

	.sf-menu {
		max-height: 0;
		overflow: hidden;
		transition: max-height 5s ease-in-out;
	}

	.sf-menu.open {
		max-height: 500px; /* Adjust based on the expected max height of your menu */
	}

	#menu .into_box {
		margin: 0;
		padding: 0;
	}
	#menu .sf-menu ul {
		box-shadow: none;
		display: block;
		width: 100%;
	}
	#menu li {
		background: none !important;
		color: #fff;
	}
	#menu .sf-menu a {
		border: none;
		margin: 0;
		padding: 0.8rem 1rem;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
		color: #fff;
		font-weight: 600;
		font-size: 1rem;
	}
	#menu .sf-menu a:hover {
		background: none !important;
		opacity: 0.9;
	}
	#menu .sf-menu li {
		white-space: normal;
	}
	#menu .sf-menu li li {
		background: rgba(0, 0, 0, 0.15);
	}
	#menu .sf-menu li li a {
		padding-left: 2.5rem;
	}
	#menu .sf-menu li li li a {
		padding-left: 3.75rem;
	}

	#menu h1 {
		font-size: 2rem;
		color: #fff;
	}

	#mobnav-btn {
		padding: 1.1rem;
	}
	#mobnav-btn i {
		font-size: 24px;
		color: #fff;
	}
	#mobnav-btn .fa-stack {
		color: #fff;
	}
	.mobnav-subarrow {
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
		z-index: 10000000;
		font-size: 1.5rem;
		padding: 0.47rem 1.1rem;
		background: rgba(0, 0, 0, 0.15);
	}
	.mobnav-subarrow i {
		color: #fff;
	}
	.sf-menu {
		width: 100% !important;
		display: none;
		margin: 0;
	}
	.sf-menu > li {
		background: none;
	}
	.sf-menu a {
		border-left: none;
	}
	.sf-menu.xactive {
		display: block !important;
	}
	.sf-menu li {
		float: none !important;
		display: block !important;
		width: 100% !important;
	}
	.sf-menu li a {
		float: none !important;
	}
	.sf-menu ul {
		position: static !important;
		display: none !important;
	}
	.xpopdrop > ul {
		display: block !important;
	}

	/* Amaguem les fletxes per defecte */
	.sf-arrows .sf-with-ul:after {
		content: none;
	}

	#menu a.selected {
		color: #ababab;
		text-decoration: underline;
		font-weight: 600;
	}
}

@media only screen and (min-width: 992px) {
	#menu {
		font-family: "Roboto", sans-serif;
	}

	#menu .sf-menu {
		margin: 0;
		float: none;
	}

	#mobnav-btn,
	.titol_nav,
	.mobnav-subarrow {
		display: none;
	}

	.sf-menu li {
		float: none !important;
		width: 150px;
		white-space: normal;
		*white-space: normal; /* ...unless you support IE7 (let it wrap) */
		-webkit-transition: background 0.2s;
		transition: background 0.2s;
		background: transparent;
		align-content: center;
		height: 86px;
	}
	.sf-menu > li > a {
		text-align: center;
		text-transform: uppercase;
		margin: 1rem 0 !important;
		padding: 0 !important;
		font-weight: 400;
		font-size: 1rem;
	}
	.sf-menu > li:first-of-type > a {
		border-left: none;
	}
	.sf-menu > li:last-of-type > a {
	}
	/* desplegable */
	.sf-menu ul {
		/*box-shadow: none;*/
		box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
		background: $menuBgSubmenu;
		width: 100%;
	}
	.sf-menu ul li {
		background: transparent !important;
		border-top: 1px solid $menuColorSeparator;
	}
	/*.sf-menu li:hover, .sf-menu li.sfHover{background: #383838 !important;color:#fff;}*/
	.sf-menu li.sfHover a {
		color: $menuColorHover !important;
	}
	.sf-menu li li a {
		margin: 0;
		border-top: none;
		color: $menuColor;
	}
	#menu ul li.last a {
		border: none;
	}
	#menu a {
		padding: 0.7rem 1.5rem;
		white-space: nowrap;
		color: #fff;
		margin: 0;
		border-left: none;
		border-top: none;
	}
	#menu a:hover {
		text-decoration: underline;
		font-weight: 500;
		color: #fff;
	}
	#menu a.sf-with-ul {
		padding-right: 30px;
	}

	#menu li > a.has-dropdown {
		padding: 3px 20px !important;
	}
	#menu a.selected {
		color: #ababab;
		text-decoration: underline;
		font-weight: 600;
	}

	/** arrows general **/
	#menu .sf-arrows > li > a.selected.sf-with-ul:after {
		border-top-color: black !important;
	}
	.sf-arrows > li > .sf-with-ul:focus:after,
	.sf-arrows > li:hover > .sf-with-ul:after,
	.sf-arrows > .sfHover > .sf-with-ul:after {
		border-top-color: $primary;
	}
	.sf-arrows .sf-with-ul:after {
		border-top-color: #888;
		right: 1.5rem;
	}
	/** arrows submenu **/
	.sf-arrows ul .sf-with-ul:after {
		border-left-color: #888;
	}
	.sf-arrows ul li > .sf-with-ul:focus:after,
	.sf-arrows ul li:hover > .sf-with-ul:after,
	.sf-arrows ul > .sfHover > .sf-with-ul:after {
		border-left-color: $primary;
		border-top-color: #fff;
	}

	#menu .sf-menu > li > a:hover {
		font-weight: 600;
		color: #fff;
	}

	#menu li li a {
		padding: 0.6rem 1.5rem;
		border-right: none;
		text-transform: uppercase;
		white-space: normal;
		/*background: rgba(255,255,255,0.9);*/
		color: $menuColor !important;
		font-size: 0.94rem;
		font-weight: 400;
	}
	#menu li li a:hover {
		color: $menuColorHover !important;
	}

	#menu li.has-dropdown a {
		padding-right: 26px;
	}

	.sf-menu li:hover,
	.sf-menu li.sfHover {
		background: transparent;
		/* only transition out, not in */
		-webkit-transition: none;
		transition: none;
	}
	.bg-menu {
		transition: background-color 1s ease, height 1s ease; /* Ensure smooth transition */
	}

	header.compressed .bg-menu {
		background-color: #fff;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11);

		#menu a.selected {
			color: #2574bc;
		}
		#menu a {
			color: #000;
		}

		.sf-menu li {
			height: 66px;
		}
	}
}
