/*==========  Mobile First Method  ==========*/
html {
	font-size: 14px;
}

#slide {
	color: #fff;

	h2 {
		font-size: 1rem;
		text-shadow: 2px 0 10px $primary;
	}

	p {
		font-size: 0.8rem;
		text-shadow: 2px 0 10px $primary;
	}
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
}

/* Small Devices, Tablets */
@media only screen and (min-width: 769px) {
	header {
		position: sticky;
		top: 0;
		z-index: 100;
		background: #fff;
		transition: 0.3s min-height;
		box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 5px 0 rgb(0 0 0 / 23%);

		&.compressed {
			/*	  #logo h1 {
			  font-size: 2rem;
			}
	  */
		}

		> .container:first-of-type {
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
		}

		.bg-menu {
			background: $menuBg;
			/*border-bottom: 1px solid $menuColorSeparator;*/
			position: fixed;
			width: 100%;
			background: transparent;
			border-bottom: 0.5px #fff solid;
			top: 0;
			left: 0;
		}
	}

	#slide {
		h2 {
			font-size: 3.5rem;
		}

		p {
			font-size: 2.5rem;
		}
	}

	.icons {
		display: flex;

		a {
			background: $boxBg;
			width: 2.4rem;
			height: 2.4rem;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 100%;
			margin-left: 0.5rem;
			color: $boxColor;

			&:hover {
				color: #000;
			}
		}

		i {
			font-size: 1.2rem;
		}
	}
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
	html {
		font-size: 16px;
	}
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1400px) {
	html {
		font-size: 16px;
	}
}

/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
	#slide-2 {
		.contingutslide {
			.slidetext {
				padding: 1rem 0 2rem 0;
			}
			.slideimg img {
				height: 150px;
			}
			.slidebuto .btn {
				padding: 0.6rem 2.9rem;
			}
		}
	}
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
	.nivo-caption .tbl .tbl-cell {
		vertical-align: middle;
	}

	footer {
		.footertextalign2 {
			text-align: center;
			margin-top: 2rem;
		}

		.titlefooter1 {
			text-align: center;
		}
	}
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
	body {
		padding-top: 4rem;
	}

	.container {
		min-width: 100%;
	}

	.jumbotron {
		padding: 0;
		margin: 0;
	}
	.jumbotron h1 {
		font-size: 2rem;
		padding: 1rem;
	}

	footer .column {
		width: 100% !important;
	}

	.idiomes li a {
		padding: 0.4rem;
	}

	#slide-2 {
		.swiper-slide {
			min-height: 298px;
			img {
				min-height: 298px;
			}
		}

		.contingutslide {
			margin-top: 0;
			.slidetext {
				padding: 1rem 0 1.5rem 0;
			}
			.slideimg img {
				height: 125px;
				min-height: 125px;
			}
			.slidebuto .btn {
				padding: 0.5rem 2.3rem;
			}
		}
	}

	#serveis {
		padding: 6rem 0;
	}
	#contact iframe {
		width: 450px;
		height: 300px;
	}

	#serveistatic1 {
		margin-bottom: 5rem;
	}

	#about-us p {
		font-size: 18px;
		line-height: 28px;
	}
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
	#slide-2 {
		.contingutslide {
			.slidetext p {
				color: #fff;
				font-size: 17px;
			}
		}
	}
	#contact iframe {
		width: 350px;
		height: 225px;
	}
	#contact .contactme {
		margin-left: 1rem;
	}
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
}

@media (min-width: 1200px) {
	.container {
		max-width: 1200px;
	}
}
